import React from "react";

export default function CameraIcon(props) {
  return (
    <svg
      width="26"
      height="21"
      viewBox="0 0 26 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_823_8895"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="21"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H25.3948V20.8038H0V0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_823_8895)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.5578 11.4762C18.5578 14.7091 15.9304 17.3365 12.6975 17.3365C9.46454 17.3365 6.83716 14.7091 6.83716 11.4762C6.83716 8.24225 9.46454 5.61584 12.6975 5.61584C15.9304 5.61584 18.5578 8.24225 18.5578 11.4762ZM22.8553 3.49667H18.7335L17.9424 0.712031C17.8252 0.293017 17.4345 0 17.0047 0H8.59516C8.17517 0 7.80402 0.272506 7.66727 0.664172L6.70032 3.49667H2.53948C1.14277 3.49667 0 4.61892 0 6.00587V18.293C0 19.68 1.14277 20.8042 2.53948 20.8042H22.8553C24.252 20.8042 25.3948 19.68 25.3948 18.293V6.00587C25.3948 4.61892 24.252 3.49667 22.8553 3.49667Z"
          fill="#909FBA"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6973 7.08154C10.2652 7.08154 8.302 9.04476 8.302 11.4768C8.302 13.8981 10.2652 15.872 12.6973 15.872C15.1293 15.872 17.0925 13.8981 17.0925 11.4768C17.0925 9.04476 15.1293 7.08154 12.6973 7.08154Z"
        fill="#909FBA"
      />
    </svg>
  );
}
